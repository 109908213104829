import { ReactNode } from "react";
import { TablerIconsProps } from "@tabler/icons-react";
import { IBlogState } from "./features/blogs";
import { IServiceState } from "./features/company_services";
import { ICompanyState } from "./features/companies";
import { IGalleryState } from "./features/gallery/gallery.types";
import { ICompanyInfoState } from "./features/company_info";
import { ITaskState } from "./features/company_tasks";

export const featureNameToReducerKeyMap = {
  "dashboard-core": "dashboard",
  "gallery-core": "gallery",
  "blogs-core": "blogs",
  "services-core": "services",
  "tasks-core": "tasks",
  "company-info": "companyInfo",
  "companies-core": "companies",
} as const;

export type FeatureNameToReducerKeyMap = typeof featureNameToReducerKeyMap;
export type ControlXFeatures = keyof typeof featureNameToReducerKeyMap;

export interface IFeature {
  name: ControlXFeatures;
  icon: (props: TablerIconsProps) => JSX.Element;
  label: string;
  component: ReactNode;
}

export type FeatureStateMapping = {
  blogs: IBlogState;
  services: IServiceState;
  tasks: ITaskState;
  gallery: IGalleryState;
  companies: ICompanyState;
  companyInfo: ICompanyInfoState;
  dashboard: ICompanyState;
  // Add other mappings as needed
};
