import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { rootSaga } from "./rootSaga";
import { combineReducers } from "redux";
import { createReduxHistory, routerMiddleware, routerReducer } from "./history";
import { userReducer } from "./user/userReducer";
import { blogReducer } from "../features/blogs";
import { serviceReducer } from "../features/company_services";
import { galleryReducer } from "../features/gallery/gallery.slice";
import { companyReducer } from "../features/companies";
import { companyInfoReducer } from "../features/company_info";
import { taskReducer } from "../features/company_tasks";

const rootReducer = combineReducers({
  router: routerReducer,
  user: userReducer,
  blogs: blogReducer,
  services: serviceReducer,
  gallery: galleryReducer,
  companies: companyReducer,
  companyInfo: companyInfoReducer,
  dashboard: userReducer,
  tasks: taskReducer,
  // ... other reducers
});

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false })
      .prepend(sagaMiddleware)
      .concat(routerMiddleware),

  devTools: true,
});

sagaMiddleware.run(rootSaga);

export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof rootReducer>;
