import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTasks,
  deleteTask,
  reorderTasksRequest,
  updateTask,
} from "./tasks.slice";
import { ITask } from "./tasks.types";
import {
  Button,
  Paper,
  Group,
  Loader,
  Divider,
  Checkbox,
  Chip,
} from "@mantine/core";
import styled from "styled-components";
import { RootState } from "../../redux/store";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { IconGripVertical } from "@tabler/icons-react";
import { AddTaskForm } from "./tasks.form";

const ControlContainer = styled.div`
  padding: 20px;
`;

export const TasksControl: React.FC = () => {
  const dispatch = useDispatch();
  const { tasks, loading } = useSelector((state: RootState) => state.tasks);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingTask, setEditingTask] = useState<ITask | null>(null);
  const [filter, setFilter] = useState<string>("all");

  useEffect(() => {
    dispatch(fetchTasks());
  }, [dispatch]);

  const handleEdit = (task: ITask) => {
    setEditingTask(task);
    setIsEditing(true);
  };

  const handleDelete = (id: string) => {
    dispatch(deleteTask(id));
  };

  const handleCreate = () => {
    setEditingTask(null);
    setIsEditing(true);
  };

  const handleToggleComplete = (task: ITask, checked: boolean) => {
    const updatedTask = {
      ...task,
      completed_at: checked ? new Date().toISOString() : "",
    };
    dispatch(updateTask(updatedTask));
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const reorderedTasks = reorder(
      tasks,
      result.source.index,
      result.destination.index
    ) as ITask[];
    dispatch(reorderTasksRequest(reorderedTasks));
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const filteredTasks = tasks.filter((task) => {
    if (filter === "all") return true;
    return task.content?.tags?.includes(filter);
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <ControlContainer>
        <Group mb="md">
          <Button onClick={handleCreate}>+ Add</Button>
          <Group>
            <Chip checked={filter === "all"} onChange={() => setFilter("all")}>
              All
            </Chip>
            <Chip
              checked={filter === "today"}
              onChange={() => setFilter("today")}
            >
              Today
            </Chip>
            <Chip
              checked={filter === "tomorrow"}
              onChange={() => setFilter("tomorrow")}
            >
              Tomorrow
            </Chip>
            <Chip
              checked={filter === "upcoming"}
              onChange={() => setFilter("upcoming")}
            >
              Upcoming
            </Chip>
          </Group>
        </Group>
        {isEditing && (
          <AddTaskForm
            task={editingTask}
            onCancel={() => setIsEditing(false)}
          />
        )}
        <Paper withBorder shadow="md" p="md" mt="md">
          <div>
            <h2>Tasks</h2>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {filteredTasks.map((task, index) => (
                      <Draggable
                        key={task.id.toString()}
                        draggableId={task.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Divider pb="sm" />
                            <Group justify="space-between">
                              <Group>
                                <div {...provided.dragHandleProps}>
                                  <IconGripVertical />
                                </div>
                                <Checkbox
                                  checked={!!task.completed_at}
                                  onChange={(e) =>
                                    handleToggleComplete(
                                      task,
                                      e.currentTarget.checked
                                    )
                                  }
                                  mr="sm"
                                />
                                <span>{task.content?.desc}</span>
                              </Group>
                              <Group>
                                <Button
                                  onClick={() => handleEdit(task)}
                                  variant="outline"
                                  size="xs"
                                  ml="sm"
                                >
                                  რედაქტირება
                                </Button>
                                <Button
                                  onClick={() => handleDelete(task.id)}
                                  variant="outline"
                                  size="xs"
                                  color="red"
                                  ml="sm"
                                >
                                  წაშლა
                                </Button>
                              </Group>
                            </Group>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Paper>
      </ControlContainer>
    </div>
  );
};
