import { PayloadAction } from "@reduxjs/toolkit";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import {
  createTask,
  fetchTasks,
  deleteTask,
  setTasks,
  setLoading,
  updateTask,
  readTask,
  reorderTasks,
  reorderTasksRequest,
} from "./tasks.slice";
import { ITask, ITaskState } from "./tasks.types";
import { API } from "../../api";
import { v4 as uuidv4 } from "uuid";

function* fetchTasksSaga(): SagaIterator {
  try {
    yield put(setLoading(true));
    const response = yield call(API.get, "/tasks");
    yield put(setTasks(response.data));
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error fetching tasks:", error);
    yield put(setLoading(false));
  }
}

function* reorderTasksSaga(action: PayloadAction<ITask[]>): SagaIterator {
  try {
    yield put(setLoading(true));

    const reorderedTaskIds = action.payload.map((task) => task.id);
    const taskIdsString = reorderedTaskIds.join("%2C");

    console.log("Sending taskIdsString", `/tasks/reorder/${taskIdsString}`);

    const response = yield call(
      API.put,
      `/tasks/reorder/${taskIdsString}`,
      null, // No body is needed
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      yield put(reorderTasks(action.payload));
    }
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error reordering tasks:", error);
    yield put(setLoading(false));
  }
}

function* createTaskSaga(action: PayloadAction<ITask>): SagaIterator {
  try {
    yield put(setLoading(true));

    const taskData: ITask = {
      ...action.payload,
      id: action.payload.id || uuidv4(),
    };

    const response = yield call(API.post, "/tasks", taskData, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    console.log("createTaskSaga response", response);
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error creating task:", error);
    yield put(setLoading(false));
  }
}

function* readTaskSaga(action: PayloadAction<string>): SagaIterator {
  try {
    yield put(setLoading(true));

    const tasks = yield select((state: ITaskState) => state.tasks);
    // const task = tasks.find((task: ITask) => task.id === action.payload);
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error readTaskSaga task:", error);
    yield put(setLoading(false));
  }
}

function* updateTaskSaga(action: PayloadAction<ITask>): SagaIterator {
  try {
    if (!action.payload.id) {
      console.error(
        "Attempting to update a task without a valid ID:",
        action.payload
      );
      return;
    }
    yield put(setLoading(true));
    const response = yield call(
      API.put,
      `/tasks/${action.payload.id}`,
      action.payload
    );
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error updating task:", error);
    yield put(setLoading(false));
  }
}

function* deleteTaskSaga(action: PayloadAction<number>): SagaIterator {
  try {
    yield put(setLoading(true));
    yield call(API.delete, `/tasks/${action.payload}`);
    yield put(setLoading(false));
  } catch (error) {
    console.error("Error removing task:", error);
    yield put(setLoading(false));
  }
}

export function* initTaskSagas() {
  yield takeLatest(fetchTasks.type, fetchTasksSaga);

  yield takeLatest(createTask.type, createTaskSaga);
  yield takeLatest(readTask.type, readTaskSaga);
  yield takeLatest(updateTask.type, updateTaskSaga);
  yield takeLatest(deleteTask.type, deleteTaskSaga);
  yield takeLatest(reorderTasksRequest.type, reorderTasksSaga);

  yield call(fetchTasksSaga);
}
