import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ITask, ITaskState } from "./tasks.types";

// Initial state
const initialState: ITaskState = {
  tasks: [],
  loading: false,
  selectedTaskID: "",
  activeTask: {
    id: "",
    label: "",
    created_at: "",
    completed_at: "",
    content: {
      image: "string",
      heading: "string",
      subheading: "string",
      desc: "string",
      tags: ["string"],
    },
  },
};

// Create slice
const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    fetchTasks() {
      // This is just a trigger for the fetchTasksSaga
    },
    reorderTasksRequest(state, action: PayloadAction<ITask[]>) {
      // This action will trigger the saga to call the API
    },
    createTask: (state, action: PayloadAction<ITask>) => {
      state.tasks.push(action.payload);
    },
    readTask: (state, action: PayloadAction<string>) => {
      state.activeTask = state.tasks.find(
        (task: ITask) => task.id === action.payload
      );
    },
    updateTask: (state, action: PayloadAction<ITask>) => {
      const index = state.tasks.findIndex(
        (task) => task.id === action.payload.id
      );
      if (index !== -1) {
        state.tasks[index] = action.payload;
      } else {
        console.warn("Attempted to update a task that doesn't exist");
      }
    },
    deleteTask(state, action: PayloadAction<string>) {
      state.tasks = state.tasks.filter((task) => task.id !== action.payload);
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setSelectedTaskID(state, action: PayloadAction<string>) {
      state.selectedTaskID = action.payload;
    },
    setTasks(state, action: PayloadAction<ITask[]>) {
      state.tasks = action.payload;
    },
    reorderTasks(state, action: PayloadAction<ITask[]>) {
      state.tasks = action.payload;
    },
  },
});

export const taskReducer = taskSlice.reducer;
export const {
  fetchTasks,
  setLoading,
  setTasks,
  setSelectedTaskID,
  createTask,
  readTask,
  updateTask,
  deleteTask,
  reorderTasks,
  reorderTasksRequest,
} = taskSlice.actions;
