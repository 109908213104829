import type React from "react";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthenticated, setUser } from "../../redux/user/userReducer";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { useScript } from "./useScript";
import { Center, Image, Text, TextInput, Button, Paper } from "@mantine/core";
import { API } from "../../api";

const Login = () => {
  const routeOrigin = useSelector(
    (state: any) => state.router.location.state?.from.pathname
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const googlebuttonref = useRef(null);
  const [pin, setPin] = useState("");
  const [pinError, setPinError] = useState("");
  const [isPinVerified, setIsPinVerified] = useState(false);

  useScript("https://accounts.google.com/gsi/client", () => {
    if (isPinVerified) {
      window.google?.accounts?.id?.initialize({
        client_id: process.env.REACT_APP_GOOGLE_OATH_CLIENT_ID,
        auto_select: false,
        callback: onSuccess,
        error_callback: onError,
      });

      window.google?.accounts?.id?.renderButton(googlebuttonref.current, {
        size: "medium",
      });
    }
  });

  function onError(err: string) {
    console.log("onError", err);
  }

  function onSuccess(response: any) {
    const userCred = response.credential;
    const user = jwtDecode(userCred);
    if (user) {
      localStorage.setItem("user/accessToken", userCred);
      // @ts-ignore
      localStorage.setItem("user/given_name", user?.given_name);
      // @ts-ignore
      localStorage.setItem("user/picture", user?.picture);

      dispatch(setAuthenticated(true));
      dispatch(setUser(user));

      if (routeOrigin) {
        navigate(routeOrigin, { replace: true });
      } else {
        navigate("/admin", { replace: true });
      }
    }
  }

  const [company, setCompany] = useState<any>();
  useEffect(() => {
    (async () => {
      try {
        const response = await API.get(
          `/companies/${process.env.REACT_APP_COMPANY_ID}`
        );
        setCompany(response.data);
      } catch (error) {
        console.error("Failed to fetch current companies:", error);
      }
    })();
  }, []);

  const handlePinSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Fake PIN verification (replace with actual verification in production)
    if (pin === "2025") {
      setIsPinVerified(true);
      setPinError("");
    } else {
      setPinError("Invalid PIN. Please try again.");
    }
  };

  return (
    <Center
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        gap: 32,
      }}
    >
      <div style={{ marginBottom: 20 }}>
        <Image src={company?.logo_url} mah={40} />
      </div>

      <Paper
        shadow="xs"
        p="md"
        style={{
          width: 300,
          background: "white",
          borderRadius: 8,
          overflow: "hidden",
        }}
      >
        <Text size="xl" mb="md">
          Sign in
        </Text>

        {!isPinVerified ? (
          <form onSubmit={handlePinSubmit}>
            <TextInput
              placeholder="Enter PIN"
              value={pin}
              onChange={(e) => setPin(e.currentTarget.value)}
              error={pinError}
              type="password"
              mb="sm"
            />
            <Button type="submit" fullWidth>
              Verify PIN
            </Button>
          </form>
        ) : (
          <div
            ref={googlebuttonref}
            style={{ display: "flex", justifyContent: "center" }}
          ></div>
        )}
      </Paper>
    </Center>
  );
};

export default Login;
