import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { createTask, updateTask } from "./tasks.slice";
import { ITask } from "./tasks.types";
import { Button, Textarea, Paper, Group, Divider } from "@mantine/core";
import styled from "styled-components";
import { ListInputForm } from "../../components/ListInputForm";
import { v4 as uuidv4 } from "uuid";

const EditForm = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface AddTaskFormProps {
  task?: ITask | null;
  onCancel: () => void;
}

export const AddTaskForm: React.FC<AddTaskFormProps> = ({ task, onCancel }) => {
  const dispatch = useDispatch();
  const [editingTask, setEditingTask] = useState<ITask | null>(
    task || {
      label: "",
      id: "",
      created_at: "",
      completed_at: "",
      content: {
        image: "",
        heading: "",
        subheading: "",
        desc: "",
        tags: [],
      },
    }
  );

  const handleSave = async () => {
    if (editingTask) {
      if (editingTask.id) {
        dispatch(
          updateTask({
            ...editingTask,
            content: { ...editingTask.content, tags: editingTask.content.tags },
          })
        );
      } else {
        dispatch(
          createTask({
            ...editingTask,
            id: uuidv4(),
            content: { ...editingTask.content },
          })
        );
      }
      onCancel();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editingTask) {
      setEditingTask({ ...editingTask, [e.target.name]: e.target.value });
    }
  };

  const handleContentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string
  ) => {
    if (editingTask) {
      setEditingTask({
        ...editingTask,
        content: { ...editingTask.content, [key]: e.target.value },
      });
    }
  };

  const handleHighlightsChange = (values: string[]) => {
    if (editingTask) {
      setEditingTask({
        ...editingTask,
        content: { ...editingTask.content, tags: values },
      });
    }
  };

  return (
    <Paper withBorder shadow="md" p="md" mt="md">
      <Group justify="space-between">
        <h2>{editingTask?.id ? "Edit" : "New Task"}</h2>
        <div onClick={onCancel}>X</div>
      </Group>

      <EditForm>
        <Textarea
          label="აღწერა"
          name="desc"
          value={editingTask?.content.desc || ""}
          onChange={(e) => handleContentChange(e, "desc")}
          placeholder="აღწერა"
        />

        <Group mt="lg">
          <ListInputForm
            label="Add a Label"
            data={editingTask?.content.tags || []}
            value={editingTask?.content.tags || []}
            onChange={handleHighlightsChange}
            placeholder="დაამატე პუნქტები"
            isMultiSelect
          />
        </Group>

        <Divider />

        <Group mt="md">
          <Button onClick={handleSave}>შენახვა</Button>
        </Group>
      </EditForm>
    </Paper>
  );
};
